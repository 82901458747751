import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.dark};

  @media (min-width: 1200px) {
    min-height: 459px;
    height: 40vh;
  }
`

const Content = styled(ParseContent)`
  & h1 {
    color: ${({ theme }) => theme.color.dark};

    font-weight: ${({ theme }) => theme.font.weight.bold};
    -webkit-text-stroke: 2px ${({ theme }) => theme.color.light};

    @media (min-width: 992px) {
      font-size: 200px;
      line-height: 200px;
      padding-top: 12rem;
    }

    @media (max-width: 991.98px) {
      font-size: 100px;
      line-height: 100px;
      padding-top: 8rem;
      padding-bottom: 3rem;
    }

    @media (max-width: 575.98px) {
      font-size: 60px;
      line-height: 60px;
    }
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => {
  return (
    <Section className="position-relative">
      <div className="container">
        <Content content={fields.description} />
      </div>
    </Section>
  )
}

export default BannerDefault
